/* app.css */
:root:root {
  /* overwrite theme variable */
  --adm-button-border-radius: 50px;
  --adm-color-primary: #2bc16b;

  /* original theme variable */
  --main-background-color: #efe9d6;
  --sub-background-color: #d1f2ce;
  --box-shadow: 0 2px 3px #0000001c;
  --wrapper-border-radius: 16px;
  --avatar-border-radius: 4px;
}

body {
  padding: 0;
  margin: 0;
  font-family: 'Noto Sans JP', sans-serif !important;
}

.adm-tabs-tab {
  width: 100% !important;
  text-align: center;
}

.container {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
  width: 100%;
  min-height: calc(100vh - env(safe-area-inset-bottom, 0) - env(safe-area-inset-top, 0));
}

.fixed-top {
  background: #ffffff;
  position: fixed;
  width: 100%;
  box-sizing: border-box;
  z-index: 500;
  top: 0;
  left: 0;
}

.card-empty {
  font-weight: 400;
  color: #000000;
  background: #ffffff;
  border-radius: 23px;
  padding: 15px;
  font-size: 15px;
  line-height: 21px;
}

.adm-nav-bar-left {
  max-width: 94px;
  white-space: nowrap;
  overflow: hidden;
}

.adm-nav-bar-right {
  flex: 0 !important;
  min-width: 94px;
}

.textTruncateMaxWidth {
  display: block;
  max-width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@supports (-webkit-touch-callout: none) {
  .container {
    /* for ios safari 15, safe-area-inset-bottom is 0, so a special fix apply here */
    min-height: calc(100vh - 80px);
  }
}

@media (min-width: 476px) {
  .textTruncateMaxWidth {
    max-width: 220px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
  .textTruncateMaxWidth {
    max-width: 520px;
  }
}
